import React from 'react';
import { GlobeAltIcon, ScaleIcon } from '@heroicons/react/outline';

import illuBrandSafety from '../../images/illu-brand-safety.svg';

const features = [
  {
    name: "Protection de marque pour l'affiliation",
    description:
      'Vos affiliés ne respectent pas toujours les conditions de participation de votre programme et achètent régulièrement votre marque ? Protégez votre programme de cette pratique grâce à TY Brand Safety.',
    icon: GlobeAltIcon,
  },
  {
    name: 'Protection de marque pour les équipes marketing et juridique',
    description:
      "Si l'achat de votre marque par un concurrent est toléré, l'usage de votre marque au sein de l'annonce est lui interdit. On appelle alors cela du parasitisme de marque : l'internaute peut se méprendre sur l'identité du site qui se trouve derrière l'annonce.",
    icon: ScaleIcon,
  },
];

export default function Example() {
  return (
    <div className="py-16 pt-16 sm:pt-96 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:mx-auto lg:max-w-7xl flex flex-col-reverse sm:flex-row sm:space-x-20 items-center">
          <dl className="space-y-10 flex flex-col">
            {features.map((feature) => (
              <div key={feature.name} className="relative">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-white border border-gray-700 text-gray-700">
                    <feature.icon className="h-6 w-6" aria-hidden="true" />
                  </div>
                  <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                    {feature.name}
                  </p>
                </dt>
                <dd className="mt-2 ml-16 text-base text-gray-500">{feature.description}</dd>
              </div>
            ))}
          </dl>
          <div className="mb-4 sm:mb-0 sm:mt-16 lg:mt-0">
            <div className="sm:pl-6 lg:px-0 lg:m-0 lg:relative lg:h-full">
              <img
                className="w-full lg:h-full lg:w-auto"
                src={illuBrandSafety}
                alt="Brand Safety"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
