import React from 'react';
import { MdAddLocationAlt, MdOutlineDevices } from 'react-icons/md';
import { FaLanguage } from 'react-icons/fa';

const features = [
  {
    name: 'Multi-localisation',
    description: 'des centaines de localisations par pays',
    icon: MdAddLocationAlt,
  },
  {
    name: 'Multi-devices',
    description: 'Desktop, Tablet, Mobile, Android, iOS…',
    icon: MdOutlineDevices,
  },
  {
    name: 'Multi-lingue',
    description: 'toutes les langues sont disponibles',
    icon: FaLanguage,
  },
];

export default function Example() {
  return (
    <div className="pb-12 bg-gray-50">
      <div className="max-w-xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 text-center">
        <h2 className="text-3xl  font-title font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          Critères de surveillance
        </h2>
        <dl className="mt-10 space-y-10 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-8">
          {features.map((feature) => (
            <div key={feature.name}>
              <dt>
                <div className="flex items-center justify-center h-12 w-12 rounded-md bg-gray-700 text-white mx-auto">
                  <feature.icon className="h-6 w-6" aria-hidden="true" />
                </div>
                <p className="mt-5 text-lg leading-6 font-medium text-gray-900">
                  {feature.name}
                </p>
              </dt>
              <dd className="mt-2 text-base text-gray-500">
                {feature.description}
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
}
