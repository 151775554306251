import React from 'react';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';

import productScreenshot from '../../images/brand-safety-screenshot.png';
import PageLayout from '../../layouts/Page';
import Args from './Args';
import Benefits from './Benefits';
import HowItWorks from './HowItWorks';
import SearchEngines from './SearchEngines';
import Monitoring from './Monitoring';
import ThankYouSmallLogo from '../../images/small_logo.svg';

const Page = () => {
  return (
    <PageLayout>
      <Helmet>
        <title>Protégez l'achat de votre marque en SEA avec TY Brand Safety</title>
        <meta
          name="description"
          content="L'achat de marque sur les moteurs de recherche est une pratique commune. Cependant, bien qu'autorisé, il est nécessaire d'être vigilant sur les sites qui achètent votre marque mais aussi sur le contenu des annonces."
        />
      </Helmet>
      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:pt-24 sm:pb-16 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="font-title text-3xl font-extrabold tracking-tight text-green-brand">
              <div className="relative flex flex-column justify-center items-center">
                <img
                  className="flex-shrink-0 h-12 w-12 mr-2 text-green-brand"
                  src={ThankYouSmallLogo}
                  alt="Thank-You Analytics Logo"
                />
                <span>Brand Safety</span>
              </div>
            </h2>
            <p className="mt-4 max-w-6xl mx-auto font-title font-extrabold text-gray-900 text-2xl sm:tracking-tight lg:text-5xl">
              Protégez l'achat de votre marque en SEA
            </p>
            <p className="max-w-4xl mt-5 mx-auto text-xl text-gray-500">
              L'achat de marque sur les moteurs de recherche est une pratique commune. Cependant,
              bien qu'autorisé, il est nécessaire d'être vigilant sur les sites qui achètent votre
              marque mais aussi sur le contenu des annonces.
            </p>
          </div>
        </div>
      </div>
      <div className="bg-white sm:-mb-80">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <figure>
            <img className="" src={productScreenshot} alt="Brand Safety" />
            <figcaption className="max-w-4xl text-center text-gray-400 text-xs mx-auto">
              Exemple d'une marque qui a son propre site e-commerce et qui diffuse également son
              catalogue sur la marketplace Amazon. <a href="https://www.amazon.fr">Amazon.fr</a>{' '}
              vient ici cannibaliser les ventes en direct de son site sans son autorisation
              d'investir en publicité sur sa marque.
            </figcaption>
          </figure>
        </div>
      </div>
      <Args />
      <div className="bg-white">
        <div className="max-w-4xl mx-auto py-16 px-4 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8 lg:flex lg:items-center lg:justify-between">
          <h2 className="text-2xl font-title font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            <span className="block">Vous souhaitez protéger votre marque ?</span>
            <span className="block text-green-brand">Faites une demande de démo</span>
          </h2>
          <div className="mt-6 space-y-4 sm:space-y-0 sm:flex sm:space-x-5">
            <Link
              to="/contact"
              className="flex items-center justify-center bg-green-brand px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:bg-green-brandHover"
            >
              Demande de démo
            </Link>
          </div>
        </div>
      </div>
      <Benefits />
      <SearchEngines />

      <HowItWorks />

      <Monitoring />
    </PageLayout>
  );
};

export default Page;
